<template>
	<div class="page">
		
		<!-- 国家地区列表栏 -->
		<!-- <div class="country-container" :class="isShow?'show':''">
			<van-search v-model.trim="keyword" show-action shape="round" placeholder="请输入搜索内容" @input="inputSearch" @clear="inputSearch" @cancel="close" />
			<div class="location-list-container">
				<div v-for="(item,idx) in locationOptions" :key="idx" :data-code="item.national_code" :data-name="item.area_name" class="location-item" @click="chooseLocation">
					<div class="location_name text-cut">{{item.area_name}}</div>
					<div>+{{item.national_code}}</div>
				</div>
			</div>
		</div> -->

		<!-- Main -->
		<!-- <div :style="{'background-color': `${style.bgColor || '#fff'}`,'background-size':'100% 100%'}" class="main flex flex-direction justify-between"> -->
		<div style="background-color: rgb(249,241,238);">
			<div class="imageMain" style="display:border-box;">
				<!-- <img src="https://exhoss40.szcreativeweek.com/common/shunfeng.png" style="width:100%;height: auto;max-width: 1080px;" class="img"> -->
				

				<div class="div1" style="background-image: url(https://exhoss40.szcreativeweek.com/common/reg20250228-01.jpg);">
					<img @click="toJump" src="https://exhoss40.szcreativeweek.com/common/reg20250228-04.png" class="button1">
				</div>
				<div class="div2" style="background-image: url(https://exhoss40.szcreativeweek.com/common/reg20250228-02.jpg)">
				</div>
				<div class="div3" style="background-image: url(https://exhoss40.szcreativeweek.com/common/reg20250228-03.jpg)">
				</div>
				<!-- <img src="" style="width:100%;height: auto;max-width: 1080px;" class="img">
				<img src="" style="width:100%;height: auto;max-width: 1080px;" class="img"> -->
			</div> 
			<!-- <div class="container" style="background-color: rgb(187,20,30);padding-top: 20px;"> -->
			<div class="container" style="background-color: rgb(249,241,238);padding-top: 20px;">
				<!--<div class="flow-type">
					<img :src="style.flowTypeImg || flowTypeSrc" alt="" class="img">
				</div>-->
				<div class="content">
					<!-- VIP -->
					<!-- <div v-if="style.mode === 1" class="title">
						<img :src="style.titleImg" alt="">
					</div> -->
					<!-- @click="showCountry" -->
					<van-cell :title="locationName" :value="'+'+locationCode" is-link arrow-direction="down"  @click="popupShow=true" class="phone font13 radius" />
					<van-field v-model="phone" ref="phone" type="tel" maxlength="11" placeholder="请输入手机号" class="phone font13 radius" />
					<div class="flex justify-between input-box">
						<van-field v-model="codeVal" ref="codeVal" type="tel" maxlength="6" center placeholder="验证码" class="code font13 radius" />
						<button v-if="!isSend" type="primary" color="black" class="code-btn font12 radius flex align-center justify-center" @click="sendCode" style="background-color:black;color: white;border: 0px;" >
							<span class="letter-spacing">获取验证</span>
						</button>
						<van-button v-else type="primary" color="black" disabled class="code-btn font13 radius">
							<van-count-down ref="countDown" class="text-white" :time="time" format="ss s" @finish="isSend = false" />
						</van-button>
					</div>
					<button type="primary" block color="black" class="font16 radius letter-spacing next-btn" style="background-color:black;color: white;width: 100%;border: 0px;" @click="nextStep">立即领票</button>
					
					<!-- <div class="tips">
						<span v-if="style.tips && style.tips[0]">{{style.tips[0]}}</span>
						<span v-if="style.tips && style.tips[1]">{{style.tips[1]}}</span>
						<span v-if="style.tips && style.tips[2]">{{style.tips[2]}}</span>
						<span v-if="style.tips && style.tips[3]">{{style.tips[3]}}</span>
					</div> -->
				</div>
			</div>
			
			<div class="footer">
				<bottom :mode="style.mode" :level="style.level" language="zh" :isHome="true"></bottom>
			</div>
			
			<van-popup v-model="popupShow" style="width: 100%;height: 45%;overflow: hidden;" position="bottom">
				<van-search v-model.trim="keyword" show-action shape="round" placeholder="请输入搜索内容" @input="inputSearch" @clear="inputSearch" @cancel="close" />
				<div style="height: calc(100% - 30px);overflow-y: auto;">
					<div style="width:100%;font-size: 14px;">
						<div v-for="(item,idx) in locationOptions" :key="idx" :data-code="item.national_code" style="padding:5px 20px;height: 30px;" :data-name="item.area_name" @click="chooseLocation">
							<div style="float:left;">{{item.area_name}}</div>
							<div style="float:right;">+{{item.national_code}}</div>
							<div style="clear: both;"></div>
						</div>
					</div>
				</div> 
			</van-popup>
		</div>
		
		
    <div v-if="isShowErrTips" class="mask-layer">
      <div class="tips-box">
        <div class="tips">提示:</div>
        <div class="">
          <div class="tips-title">对不起，你曾经用微信小程序报名过,</div>
          <div class="tips-title">请用微信小程序找回你的入场码</div>
        </div>
        <div class="close-btn" :style="{'background-color': style.mainColor || btnColor}" @click="closeBindTips">Close</div>
      </div>
    </div>

    <div @click="toJump" v-if="buttonShow" style="position:fixed;top:calc(40% - 30px) ;right:10px;width:60px;height:60px;background-color:black;border-radius: 30px;font-size: 18px;padding: 5px;color: white; box-shadow: 5px 5px 5px rgba(0,0,0,0.5);">
      免费<br>领票
    </div>
	</div>
</template>

<script>
	
	import commonMixin from '@/view/process/mixin/common.js';
	import loginMixin from '@/view/process/mixin/login.js';

	export default {
		mixins: [commonMixin, loginMixin],
		data() {
			return {
				popupShow:false,
        buttonShow: true
			}
		},
    
    // mounted() {
    //   this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
    // },
		created() {
			this.setLanguge('zh'); // 设置语言环境
			this.getCountryList('zh'); // 获取国家列表
			this.setDefaultLocation(); // 设置默认地区

      // this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
      window.addEventListener('scroll', this.handleScroll);
		},
		methods: {
			toJump(){
        // window.scrollTo(0, document.body.scrollHeight);
        window.scrollTo({
          top: document.body.scrollHeight, // 滚动到页面底部
          left: 0,
          behavior: 'smooth'
        });
      },
      handleScroll(e) {
        const scrollHeight = window.scrollY || document.documentElement.scrollTop;
        if(scrollHeight < 2500 )
          this.buttonShow = true
        else
          this.buttonShow = false
        // console.log('滚动后的高度:', scrollHeight);
        // console.log(e);
      }
		}
	}
</script>

<style lang="less" scoped>
	@var: 3vh;

	@media (max-width: 800px) {
		.imageMain{
			width: 100%;
		}
		.div1 {
			background-size: 100% 100%; width: 100vw;height:349.26vw;
			background-repeat: no-repeat;			
		}
		.div2 {
			background-size: 100% 100%; width: 100vw;height:318.1481vw;
			background-repeat: no-repeat;			
		}
		.div3 {
			background-size: 100% 100%; width: 100vw;height:321.9444vw;
			background-repeat: no-repeat;			
		}
		.button1{
			width:50%;height: auto;max-width: 1080px;margin-top: 199vw;cursor: pointer;
		}
	}
	
	/* 针对大屏幕设备，例如电脑 */
	@media (min-width: 801px) {
		.imageMain{
			width: 255px;
			margin: 0 auto;
		}
		.div1 {
			background-size: 100% 100%; 
			background-repeat: no-repeat;
			width: 255px;height:890.6111px; 	
		}
		.div2 {
			background-size: 100% 100%; 
			width: 255px;height:811.44px; 	
			background-repeat: no-repeat;
			margin: 0 auto;
		}
		.div3 {
			background-size: 100% 100%; 
			width: 255px;height:820.96px; 	
			background-repeat: no-repeat;	
			margin: 0 auto;		
		}
		.button1{
			width:50%;height: auto;
			margin-top: 509px;
			cursor: pointer;
			z-index: 1000;
		}
	}
  
  .mask-layer {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 10;
  }
   
  .tips-box {
    position: fixed;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 280px; 
    height: 240px;
    padding: 20px 15px;
    background-color: #fff;
    border-radius: 6px;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tips-box .tips {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .tips-title {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 26px;
  }
  .close-btn {
    font-size: 20px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    width: 160px;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    margin: 30px auto 0;
  }
	
	.page {
		overflow-x: hidden;
		background-color: #fff;
		margin: 0px;
		padding: 0px;
		width: 100%;
		max-width: 1200px;
		height:auto;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
	.row-scroll-box {
		width: 100%;
		max-height: 1400px;
		display: flex;
	}
	.country-container {
		background-color: #FFFFFF;
		width: 100%;
		// height: calc(100vh - constant(safe-area-inset-bottom)); /* 兼容 IOS < 11.2 */
		// height: calc(100vh - env(safe-area-inset-bottom)); /* 兼容 IOS > 11.2 */
		 
		// transition: margin-left 500ms;
		overflow-x: hidden;
		
		.location-list-container {
		  margin: 0 20px;
		}
		
		.location-item {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  height: 45px;
		  color: #333;
		  font-size: 14px;
		}
		
		.location-item + .location-item {
		  border-top: 1px solid #eee
		}
		
		.location_name {
		  width: 250px;
		}
	}
	.show {
		margin-left: 0;
	}
	
	.main {
		width: 100vw;
		min-height: 100vh;
		background-size: 100% 100%;
	}
	
	.img {
		width: 100%;
	}
	
	.logo {
		width: 160px;
		margin: 40px auto 20px;
	}
	.flow-type {
		width: 300px;
		margin: 0 auto 25px;
	}
	
	.container {
		width: 100%;
		margin: 0 auto;
		
		.title {
			width: 120px;
			margin: 0 auto 3vh;
		}
		
		.tabs {
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-bottom: @var;
			font-size: 20px;
			font-weight: bold;

			span {
				padding-bottom: 2px;
			}

			.active {
				border-bottom-style: solid;
				border-bottom-width: 4px;
			}
		}
		
		.content {
			width: 255px;
			margin: 0 auto ;
			
			.phone {
				display: flex;
				align-items: center;
				height: 40px;
				margin-bottom: @var;
			}

			.code {
				height: 40px;
				margin-right: 14px;
			}
			
			/deep/ .van-cell {
				padding: 10px 10px 10px 15px;
				
				.van-cell__title {
					flex: 3;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					text-align: left;
				}
			 	.van-cell__value {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				
				.van-cell__right-icon {
				  margin-left: 10px;
				}
			}
			
			/deep/ .van-button--normal {
				padding: 0;
			}

			.code-btn {
				height: 40px;
				width: 180px;
			}
			
			.input-box {
				margin-bottom: @var;
			}
			
			.next-btn {
				height: 40px;
				margin-bottom: 6vh;
			}
			.tips {
				color: #fff;
				margin-bottom: 2vh;
				
				span {
					display: block;
					font-size: 12px;
					line-height: 20px;
					text-align: center;
				}
			}
			
		}

	}
	
</style>
